export interface Example {
  audience: string;
  product: string;
  limit: number;
}

const defaultLimit = 230;

export const examples: Example[] = [
  {
    audience: "cat owners",
    product: "Self-cleaning cat liter box",
    limit: defaultLimit,
  },
  {
    audience: "tech enthusiasts",
    product: "Self-running shoes",
    limit: defaultLimit,
  },
  {
    audience: "tech workers",
    product: "Ergonomic mechanical keyboard",
    limit: defaultLimit,
  },
  {
    audience: "marketing specialists",
    product: "Funkt is an AI powered copywriting tool",
    limit: defaultLimit,
  },
];

interface EndpointReturn {
  error?: string;
  result?: string;
}

function randnth<T>(items: T[]): [number, T] {
  const i = Math.floor(Math.random() * items.length);
  return [i, items[i]];
}

const url = "https://us-central1-funkt-ai.cloudfunctions.net/demo";

export function getExample(): Example {
  const [, example] = randnth(examples);
  return example;
}

export async function completeExample(
  example: Example,
  completions: string[]
): Promise<string> {
  const payload = { ...example, completions: completions };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(payload),
  });

  const result = (await response.json()) as EndpointReturn;
  if (result.error !== undefined) {
    console.error(
      `Error fetching data for ${JSON.stringify(example)}: ${JSON.stringify(
        result
      )}`
    );
  }

  return result.result ?? "";
}
