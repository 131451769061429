import { useState, useEffect } from "react";

const Text = (props: { text: string }) => {
  const { text } = props;
  const [visLen, setVisLen] = useState(0);

  useEffect(() => {
    if (text.length === 0) {
      setVisLen(0);
    }
  }, [text.length]);

  useEffect(() => {
    if (visLen <= text.length) {
      setTimeout(() => {
        setVisLen((l) => Math.min(l + 1, text.length));
      }, 10);
    }
  });

  const visibleText = text.slice(0, visLen);
  return <span>{visibleText}</span>;
};

export default Text;
