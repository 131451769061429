import { useState, useEffect } from "react";
import "./DemoBox.css";
import { getExample, completeExample, Example } from "./examples";
import Text from "./Text";

const DemoBox = () => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [eoc, setEoc] = useState<boolean>(false);
  const [completions, setCompletions] = useState<string[]>([]);
  const [curExample, setCurExample] = useState<Example>({
    audience: "",
    product: "",
    limit: -1,
  });

  const loadExample = async (ex: Example, cmps: string[]) => {
    setInitialized(true);
    setLoading(true);
    const completion = await completeExample(ex, cmps);
    if (completion.trim().length === 0) {
      setEoc(true);
    }
    setCompletions((completions) => [...completions, completion]);
    setLoading(false);
  };

  const resetAndReloadExample = async () => {
    const example = getExample();
    setCompletions([]);
    setCurExample(example);
    setEoc(false);
    await loadExample(example, []);
  };

  useEffect(() => {
    if (!initialized) {
      resetAndReloadExample();
    }
  });

  let loader = <span></span>;
  let spinner = <span></span>;

  if (curExample.limit === -1) {
    spinner = <i className="loader-icon fas fa-spinner fa-spin"></i>;
  }

  if (loading) {
    loader = <span className="loader">{spinner}</span>;
  }

  let render = <span></span>;

  if (curExample.limit >= -1) {
    console.log(completions);
    const completedText = completions.join("").replace(/[\n\s]+/, " ");

    render = (
      <DemoRender
        example={curExample}
        completions={completedText}
        eoc={eoc}
        onReload={resetAndReloadExample}
        onMore={() => loadExample(curExample, completions)}
        loading={loading}
      />
    );
  }

  return (
    <>
      {loader}
      {render}
    </>
  );
};

const DemoRender = (props: {
  example: Example;
  completions: string;
  eoc: boolean;
  onReload: () => void;
  onMore: () => void;
  loading: boolean;
}) => {
  const { example, completions, eoc, onReload, onMore, loading } = props;
  const { product, audience } = example;

  let klass = "refresh link blue";
  let iKlass = "fas fa-sync";
  if (loading) {
    klass = "refresh link gray";
    iKlass = "fas fa-sync fa-spin";
  }

  let button = <span></span>;

  if (!eoc) {
    button = (
      <button className="link blue" onClick={onMore}>
        more <i className="fas fa-arrow-right"></i>
      </button>
    );
  }

  return (
    <>
      <button className={klass} onClick={onReload}>
        <i className={iKlass}></i>
      </button>

      <span className="blue">Your product</span>
      <p>
        {product} for {audience}
      </p>

      <span className="blue">Your ad</span>
      <p>
        <Text text={completions} /> {button}
      </p>
    </>
  );
};

export default DemoBox;
